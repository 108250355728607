import React from "react";
import { Link } from "@reach/router";

function SidebarHeading({ title, subpages, mainPage }) {
  return (
    <section>
      <h1>{title}</h1>
      <ul>
        {subpages.map((subpage, index) => (
          <li key={index}>
            <Link to={"/" + mainPage + "/" + subpage.subPageLink}>
              {subpage.subPageName}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default SidebarHeading;
