import React from "react";
import { Link } from "@reach/router";

function Header() {
  return (
    <React.Fragment>
      <nav className="navbar bg-dark">
        <h1>
          <a href="dashboard.html">
            <i className="fas fa-code"> GuteTeknik</i>
          </a>
        </h1>
        <ul>
          <li>
            <Link to="webb1">Webbutveckling 1</Link>
          </li>
          <li>
            <Link to="webb2">Webbutveckling 2</Link>
          </li>
          <li>
            <Link to="prog1">Programmering 1</Link>
          </li>
          <li>
            <Link to="prog2">Programmering 2</Link>
          </li>
        </ul>
      </nav>
    </React.Fragment>
  );
}

export default Header;
