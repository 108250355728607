import React from "react";
import "./css/App.css";
import { Router } from "@reach/router";

import Header from "./layout/Header";
import ContentPage from "./ContentPage";

let pages = require("./pages.json");
console.log(pages);

/*

JSON-file for getting all the pages

[{
  "PageName":"Webbutveckling 1",
  "LinkName": "webb1",
  "ContentFile": "webb1/index",
  "SubPages": [
    {
      "PageName": "Kursinfo",
      "LinkName": "kursinfo",
      "ContentFile": "webb1/kursinfo"
    },
    {
      "PageName": "Planering",
      "LinkName": "planering",
      "ContentFile": "webb1/planering"
    }
  ]
}]

Alternative:
Have each main-page be its own instance of a component


Need:
Links based on JSON file
Content from .md files


May need to get a second subpage, or something else to do Assignments, and Examples.

*/

function App() {

  return (
    <div className="App">
      <Header />
      <Router>
        <ContentPage default pageName="index" />
        <ContentPage path="webb1" pageName="webb1" />
        <ContentPage path="webb1/:subpage" pageName="webb1" />
        <ContentPage path="webb2" pageName="webb2" />
        <ContentPage path="webb2/:subpage" pageName="webb1" />
      </Router>
    </div>
  );
}

export default App;
