import React from "react";
import SidebarHeading from "./SidebarHeading";

function Sidebar({ pageData }) {
  return (
    <React.Fragment>
      <section>
        <h1>{pageData.pageName}</h1>
      </section>
      {pageData.headings.map((heading, index) => (
        <SidebarHeading
          key={index}
          mainPage={pageData.pageLink}
          title={heading.title}
          subpages={heading.subpages}
        />
      ))}
    </React.Fragment>
  );
}

export default Sidebar;
