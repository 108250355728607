import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown/with-html";
import CodeBlock from "./CodeBlock";

import Sidebar from "./layout/Sidebar";

function ContentPage({ pageName, subpage }) {
  //const [pageData, setPageData] = useState();
  const [pageContent, setPageContent] = useState("404");

  let pageData = require("./pageinfo/" + pageName + ".json");

  /*
  axios
    .get("../" + pageName + ".json") // JSON File Path
    .then(response => {
      setPageData(response.data);
    })
    .catch(function(error) {
      //setPageContent("Error getting JSON file for page (nav)");
      console.log(error);
    });
    */

  useEffect(() => {
    var query = "../" + pageName + "/" + subpage + ".md";

    axios
      .get(query)
      .then(response => {
        setPageContent(response.data);
      })
      .catch(function(error) {
        setPageContent("404");
        console.log(error);
        console.log("Query: " + query);
      });
  });

  useEffect(() => {
    document.title = "GuteTeknik - " + pageData.pageName;
  });

  return (
    <div id="main-wrapper">
      <aside>
        <Sidebar pageData={pageData} />
      </aside>
      <main>
        <ReactMarkdown
          escapeHtml={false}
          source={pageContent}
          renderers={{ code: CodeBlock }}
        />
        <script async src="https://static.codepen.io/assets/embed/ei.js" />
      </main>
    </div>
  );
}

ContentPage.defaultProps = {
  subpage: "index"
};

export default ContentPage;
